define("discourse/plugins/Mumble/discourse/components/mumble-icon", ["exports", "@glimmer/component", "@ember/service", "ember-modifier", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "discourse-common/lib/get-url", "discourse-i18n", "discourse/components/header/dropdown", "discourse/components/dropdown-menu", "@ember/object", "discourse/plugins/Mumble/discourse/initializers/extend-for-mumble", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _emberModifier, _dButton, _concatClass, _dIcon, _getUrl, _discourseI18n, _dropdown, _dropdownMenu, _object, _extendForMumble, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let addedEventHandler = false;
  class MumbleHeaderIcon extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    constructor() {
      super(...arguments);
      (0, _extendForMumble.requestInitialData)();
    }
    get title() {
      return _discourseI18n.default.t("mumble.icon.title");
    }
    toggleMumbleMenu() {
      this.mumbleVisible = !this.mumbleVisible;
      this.updateVisibility();
    }
    static #_5 = (() => dt7948.n(this.prototype, "toggleMumbleMenu", [_object.action]))();
    updateVisibility() {
      // I have no idea how to do this the discourse way >.<
      if (this.mumbleVisible) {
        document.getElementById("mumble-menu").style.display = 'block';
        document.getElementsByClassName("mumble-dropdown")[0].classList.add('active'); // no idea how to get discourse to just use an id for the button >.<
        if (!this.addedEventHandler) {
          $('body').click(e => {
            this.focusOutHandler(e);
          });
          this.addedEventHandler = true;
        }
        (0, _extendForMumble.rerenderWidgets)(); // even if data isn't available, can at least render general info
      } else {
        document.getElementById("mumble-menu").style.display = 'none';
        document.getElementsByClassName("mumble-dropdown")[0].classList.remove('active'); // no idea how to get discourse to just use an id for the button >.<
      }
    }
    focusOutHandler(e) {
      // Did user click somewhere else?  If so, dismiss popup
      if (e.target.closest('.mumble-dropdown') == null && e.target.closest('.mumble-menu-panel') == null) {
        this.mumbleVisible = false;
        this.updateVisibility();
      }
    }
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
    		<Dropdown
    			@title="mumble.icon.title"
    			@icon="microphone"
    			@className="mumble-dropdown"
    			@targetSelector=".mumble-menu-panel"
    			@onClick={{this.toggleMumbleMenu}}
    			@active={{this.mumbleVisible}}
    		/> 	
    		<div class="mumble-menu glimmer-mumble-menu" aria-live="polite" ...attributes>
    			<div class="menu-panel mumble-menu-panel drop-down" id='mumble-menu' data-max-width="500" style="display: none;">
    				(Mumble server may be offline... this will refresh if it comes back online.)
    			</div>
    		</div>
    	
    */
    {
      "id": "aNeKYKh5",
      "block": "[[[1,\"\\n\\t\\t\"],[8,[32,0],null,[[\"@title\",\"@icon\",\"@className\",\"@targetSelector\",\"@onClick\",\"@active\"],[\"mumble.icon.title\",\"microphone\",\"mumble-dropdown\",\".mumble-menu-panel\",[30,0,[\"toggleMumbleMenu\"]],[30,0,[\"mumbleVisible\"]]]],null],[1,\" \\t\\n\\t\\t\"],[11,0],[24,0,\"mumble-menu glimmer-mumble-menu\"],[24,\"aria-live\",\"polite\"],[17,1],[12],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"menu-panel mumble-menu-panel drop-down\"],[14,1,\"mumble-menu\"],[14,\"data-max-width\",\"500\"],[14,5,\"display: none;\"],[12],[1,\"\\n\\t\\t\\t\\t(Mumble server may be offline... this will refresh if it comes back online.)\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/Mumble/discourse/components/mumble-icon.js",
      "scope": () => [_dropdown.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MumbleHeaderIcon;
});